import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/Layout"
import MetaData from "../../components/meta"
import RelatedResourceCard from "../../components/RelatedResourceCard"

// Import styles
import "../../styles/kubernetes-ingress.scss"

// Images
import hero__illustration from "../../images/kubernetes-ingress/hero-illustration.svg"
import testimonials__condnast from "../../images/kubernetes-ingress/testimonials/conde-nast-logo.svg"
import testimonials__shape from "../../images/kubernetes-ingress/testimonials/shape.svg"
import architecture__diagram from "../../images/kubernetes-ingress/diagram.svg"
import features__routing from "../../images/kubernetes-ingress/routing.svg"
import features__complexity from "../../images/kubernetes-ingress/reduce-complexity.svg"
import features__secure from "../../images/kubernetes-ingress/secure.svg"
import features__scalability from "../../images/kubernetes-ingress/scalability.svg"
import distributions__redhat from "../../images/kubernetes-ingress/red-hat-open-shift.svg"
import distributions__kubernetes from "../../images/kubernetes-ingress/kubernetes.svg"
import distributions__d2iq from "../../images/kubernetes-ingress/d-2-iq.svg"
import cta__traefikee from "../../images/kubernetes-ingress/cta/traefik-ee-icon.svg"
import cta__kubernetes from "../../images/kubernetes-ingress/cta/kubernetes.svg"

interface Props {
  data: any
  location: any
}

const kubernetesIngressPage = ({ data, location }: Props) => {
  const related_resources = data.related_resources.edges

  return (
    <Layout>
      <MetaData
        location={location}
        data={{
          ghostPage: data.current_page,
        }}
      />
      <div className="ki-page">
        {/* Hero */}
        <section className="ki-hero page-hero">
          <div className="wrapper-1200">
            <div className="ki-hero__content page-hero__content">
              <h1>Kubernetes Ingress</h1>
              <p>
                A centralized routing solution for your Kubernetes deployments.
                <br />
                <i>Multi-cloud, hybrid-cloud, and on-premises.</i>
              </p>
              <div className="ki-hero__cta">
                <a
                  className="button--primary button--with-shadow button--extra-padding"
                  href="https://info.traefik.io/en/request-demo-traefik-enterprise"
                >
                  Request a Demo
                </a>
                <a
                  href="https://info.traefik.io/buy-traefik-enterprise"
                  className="button--primary button--primary--white button--with-shadow button--extra-padding"
                >
                  Get Pricing
                </a>
              </div>
            </div>
          </div>

          <div className="ki-hero__image page-hero__image">
            <img src={hero__illustration} alt="kuberetes ingress" />
          </div>
        </section>

        {/* Introduction */}
        <section className="ki-intro page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>What is Kubernetes Ingress?</h2>
              <p className="sub-title">
                Kubernetes offers multiple ways to route traffic from the
                outside world to a Kubernetes cluster. The most flexible option
                is to define an Ingress resource that declares HTTP/S endpoints
                and user-defined routing rules. Each Ingress resource is
                associated with an Ingress Controller responsible for fulfilling
                those rules.
              </p>
              <p className="sub-title">
                Deploying Traefik as your Kubernetes Ingress Controller brings
                Traefik’s power, flexibility, and ease of use to your Kubernetes
                deployments as well as the rest of your network infrastructure.
              </p>
              <div className="buttons">
                <a
                  className="button--secondary button--secondary--with-arrow"
                  href="https://traefik.io/blog/connecting-users-to-applications-with-kubernetes-ingress-controllers/"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* Testimonials */}
        <section className="ki-testimonials page-content-section">
          <div className="wrapper-1200">
            <div>
              <img
                className="logo"
                src={testimonials__condnast}
                alt="condé nast"
              />
              <p className="quote">
                "Traefik is a high-performance solution that allows us to give
                control to our development team over their ingress without
                having to operate complex configurations - We use Traefik as our
                Ingress solution for all of our Kubernetes clusters."
              </p>
              <div className="name">Josh Michielsen</div>
              <div className="designation">
                Senior Software Engineer at Condé Nast International
              </div>
              <div className="cta">
                <Link
                  className="button--secondary button--secondary--white button--secondary--with-arrow button--secondary--with-arrow--white"
                  to="/resources/traefik-conde-nast/"
                >
                  Watch Video
                </Link>
              </div>
              <div className="quote-icon">
                <img src={testimonials__shape} />
              </div>
            </div>
          </div>
        </section>

        {/* Architecture */}
        <section className="ki-architecture page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>Traefik’s Kubernetes Ingress Architecture</h2>
              <p className="sub-title">
                Traefik installs as one or more pods of controllers, ingress
                proxies, and mesh proxies in your Kubernetes cluster. <br />
                Controllers query the Kubernetes API server to automatically
                discover and update proxy routing configuration.
              </p>
            </div>
            <div className="ki-architecture__image">
              <img src={architecture__diagram} />
            </div>
          </div>
        </section>

        {/* Features */}
        <section className="ki-features page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>Why use Traefik Ingress Controller for Kubernetes?</h2>
              <div className="cta">
                <Link
                  className="button--secondary button--secondary--with-arrow"
                  to="/traefik-enterprise/"
                >
                  Explore Traefik Enterprise
                </Link>
              </div>
            </div>

            <div className="ki-features__grid">
              <div className="ki-features__grid-item">
                <img src={features__routing} alt="Advanced routing" />
                <h4>Advanced routing</h4>
                <p>
                  Traefik is a modern, full-featured router with powerful
                  capabilities out of the box
                </p>
                <ul>
                  <li>
                    <p>
                      Route any protocol––HTTP &amp; HTTPS, TCP, UDP,
                      Websockets, gRPC––through the same or different endpoints
                    </p>
                  </li>
                  <li>
                    <p>
                      Flexible routing rules on headers, host, method, path, and
                      query
                    </p>
                  </li>
                  <li>
                    <p>Out of the box HTTP cache support, on any route</p>
                  </li>
                  <li>
                    <p>
                      Rich quality of service control and traffic manipulation
                      middleware
                    </p>
                  </li>
                  <li>
                    <p>Automatic certificate management</p>
                  </li>
                  <li>
                    <p>Multiple, reusable configuration sources</p>
                  </li>
                </ul>
              </div>
              <div className="ki-features__grid-item">
                <img
                  src={features__complexity}
                  alt="Reduced complexity, increased visibility and control"
                />
                <h4>Reduced complexity, increased visibility and control</h4>
                <p>
                  Created to make complex, dynamic, cloud-native environments
                  simple and easy to use, Traefik brings that same power and
                  control to Kubernetes. This results in providing the insights
                  necessary to understand what’s happening across all of your
                  deployments, enabling you to take decisive action from day
                  one:
                </p>
                <ul>
                  <li>
                    <p>Dynamic service discovery</p>
                  </li>
                  <li>
                    <p>Embedded dashboard</p>
                  </li>
                  <li>
                    <p>
                      Built-in support for the most widely-used distributed
                      tracing, monitoring, and logging tools
                    </p>
                  </li>
                  <li>
                    <p>
                      Centralized control of Kubernetes clusters in the cloud
                      and on-premises
                    </p>
                  </li>
                  <li>
                    <p>
                      Integrated GitOps workflow, from deployment to
                      configuration
                    </p>
                  </li>
                  <li>
                    <p>Centralized API Portal</p>
                  </li>
                </ul>
              </div>
              <div className="ki-features__grid-item">
                <img src={features__secure} alt="Security" />
                <h4>Security</h4>
                <p>
                  Security has been at the forefront of Traefik’s design since
                  its inception, bringing a rich set of security capabilities to
                  your Kubernetes apps:
                </p>
                <ul>
                  <li>
                    <p>Automatic HTTPS</p>
                  </li>
                  <li>
                    <p>Encrypted cluster communication</p>
                  </li>
                  <li>
                    <p>Custom certificates</p>
                  </li>
                  <li>
                    <p>
                      Multiple authentication protocols including LDAP, JWT,
                      OAuth2, OpenID Connect, HMAC, and third-party delegation
                    </p>
                  </li>
                  <li>
                    <p>
                      Distributed Let’s Encrypt support with unlimited
                      certificates
                    </p>
                  </li>
                  <li>
                    <p>
                      Distributed, custom rate limiting and in-flight request
                      caps
                    </p>
                  </li>
                  <li>
                    <p>Vault support</p>
                  </li>
                </ul>
              </div>
              <div className="ki-features__grid-item">
                <img
                  src={features__scalability}
                  alt="High Availability at Scale"
                />
                <h4>High Availability at Scale</h4>
                <p>
                  Traefik Enterprise’s clustered control plane and independently
                  scalable data plane pairs highly available service discovery
                  with scalable throughput:
                </p>
                <ul>
                  <li>
                    <p>Automatic cluster deployment</p>
                  </li>
                  <li>
                    <p>
                      Clustered controllers deployable in multiple nodes for
                      high availability of service discovery and configuration
                    </p>
                  </li>
                  <li>
                    <p>
                      Scale out proxy pods to meet increased throughput demands
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="ki-distributions page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <hr className="hr-tiny" />
              <h2>
                First-Class Ingress For Enterprise Kubernetes Distributions
              </h2>
            </div>
            <ul>
              <li>
                <a
                  href="https://marketplace.redhat.com/en-us/products/traefik-enterprise-edition"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="logo">
                    <img src={distributions__redhat} alt="Red hat open shift" />
                  </div>
                  <div className="title">Red Hat OpenShift Certified</div>
                </a>
              </li>
              <li>
                <a
                  href="https://operatorhub.io/operator/traefikee-operator"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <div className="logo">
                    <img src={distributions__kubernetes} alt="Kubernetes" />
                  </div>
                  <div className="title">Kubernetes Operator</div>
                </a>
              </li>
              <li>
                <Link to="/blog/from-zero-to-hero-in-minutes-with-konvoys-built-in-traefik-for-kubernetes-11577fdeeb14/">
                  <div className="logo">
                    <img src={distributions__d2iq} alt="D2IQ" />
                  </div>
                  <div className="title">D2IQ official partner</div>
                </Link>
              </li>
            </ul>
            <div className="buttons">
              <a
                href="https://info.traefik.io/get-traefik-enterprise-free-for-30-days"
                className="button--secondary button--secondary--with-arrow"
              >
                Try Traefik Enterprise
              </a>
            </div>
          </div>
        </section>

        {/* Related Resources */}
        {related_resources.length > 0 && (
          <section className="ki-resources page-content-section">
            <div className="wrapper-1200">
              <div className="page-content-section__header">
                <hr className="hr-tiny" />
                <h2>Related Resources</h2>
              </div>
              <div className="related-resource-feed">
                {related_resources.map(({ node }) => (
                  <RelatedResourceCard key={node.id} post={node} />
                ))}
              </div>
            </div>
          </section>
        )}

        {/* CTA */}
        <section className="ki-cta page-footer">
          <div className="wrapper-1200">
            <h2>Ready to get started?</h2>
            <div className="ki-cta__links cta-link-cards">
              <div className="cta-link-card">
                <div className="link">
                  <a href="https://info.traefik.io/en/request-demo-traefik-enterprise/">
                    Request a demo &rarr;
                  </a>
                </div>
                <div className="image">
                  <img src={cta__traefikee} />
                </div>
              </div>
              <div className="cta-link-card cta-link-card--gradient-2">
                <div className="link">
                  <a href="https://doc.traefik.io/traefik-enterprise/installing/kubernetes/teectl/">
                    Quickstart Traefik Enterprise in K8s &rarr;
                  </a>
                </div>
                <div className="image">
                  <img src={cta__kubernetes} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default kubernetesIngressPage

export const query = graphql`
  query {
    related_resources: allGhostPost(
      filter: {
        tags: {
          elemMatch: {
            slug: { eq: "hash-kubernetes-ingress-related-resource" }
          }
        }
      }
      limit: 3
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }

    current_page: ghostPage(slug: { eq: "kubernetes-ingress" }) {
      ...PageMetaFields
    }
  }
`
